body {
  background-color: rgb(18, 46, 46) !important;
}

.exModal {
  background-color: pink;
}

.bInputDevice {
  position: relative;
  overflow: hidden;
  background-color: #1f1f1f !important;
}

.bInputDevice > .cam {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}